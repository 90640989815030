// When modal opens
document.addEventListener('shown.bs.modal', function(event) {
    if (event.target.id === 'videoModal') {
        document.getElementById('modal-video').load();
        document.getElementById('modal-video').play();
    }
});

// When modal closes
document.getElementById('videoModal').addEventListener('hidden.bs.modal', function(event) {
    document.getElementById('modal-video').pause();
});

// When play button is clicked
document.getElementById('playvideo').addEventListener('click', function() {
    document.getElementById('videoModal').style.display = 'block'; // Show the modal
});


// $(document).ready(function(){

//     $('#submit-form').on('click', function(){

//         $("#form-spinner").css('visibility','visible');
//         $("#form-response").css('display','none');

//         console.log($("#form-wrap").height());

//         $.ajax(
//             {
//                 url: "/formsubmit.php", 
//                 type: "POST",
//                 async: true,
//                 dataType: "json",
//                 headers: {'X-Requested-With': 'XMLHttpRequest'},
//                 data: {
//                     name:   $('#nameInput').val(),
//                     email:  $('#emailInput').val()
//                 },
//                 success: function(data){
//                     console.log(data);
//                     if(data.error.ErrorStatus == 0){
//                         $("#form-spinner").css('visibility','hidden');
//                         $("#form-response").css('height',$("#form-wrap").height()+"px");
//                         $("#form-response").css('text-align','center');
//                         $("#form-wrap").html('');
//                         $("#form-response").css('display','block');
//                     }
//                     else{
//                         $("#form-response").html(data.error.ErrorDesc);
//                         $("#form-spinner").css('visibility','hidden');
//                         $("#form-response").css('display','block');
                        
//                     }
//                 },
//                 error: function(){
//                     $("#form-spinner").css('visibility','hidden');
//                     $("#form-response").html('There was an error submitting the form. Please try again later.');
//                     $("#form-response").css('display','block');
//                 }
//             }
//         );

//     });
// });
